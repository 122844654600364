import {StateOperator} from '@ngxs/store';
import {patch} from '@ngxs/store/operators';
import {EntitiesStateModel} from '../model';

export function loadedEntities<T extends EntitiesStateModel<any> = EntitiesStateModel<any>>(
  loaded: boolean
): StateOperator<T> {
  // @TODO: Remove "as any"
  return patch<EntitiesStateModel<any>>({
    loaded,
  }) as any;
}
