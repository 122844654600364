import {StateOperator} from '@ngxs/store';
import {patch} from '@ngxs/store/operators';
import {EntitiesStateModel} from '../model';

export function setError<T extends EntitiesStateModel<any> = EntitiesStateModel<any>>(error: any): StateOperator<T> {
  // @TODO: Remove "as any"
  return patch<EntitiesStateModel<any>>({
    error,
  }) as any;
}
