import {StateOperator} from '@ngxs/store';
import {append, patch} from '@ngxs/store/operators';
import {EntitiesStateModel} from '../model';
import {ensureArray} from '../internal';
import Dictionary = MsApp.Dictionary;

export function addOrReplaceEntities<
  T extends MsApp.Dictionary,
  R extends EntitiesStateModel<T> = EntitiesStateModel<T>,
>(idKey: Extract<keyof T, string>, entities: T | T[]): StateOperator<R> {
  // @TODO: Remove "as any"
  return (state: Readonly<R>) => {
    const {entities: currentEntities} = state;
    const newEntities: Dictionary<T> = {};
    const appendIds: string[] = [];

    ensureArray(entities).forEach(entity => {
      const id = entity[idKey] as any;

      if (!(id in currentEntities)) {
        appendIds.push(id);
      }

      newEntities[id] = entity;
    });

    return patch<EntitiesStateModel<T>>({
      entities: {...state.entities, ...newEntities},
      ids: append<string>(appendIds),
    })(state) as any;
  };
}
