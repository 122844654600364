import {StateOperator} from '@ngxs/store';
import {patch} from '@ngxs/store/operators';
import {EntitiesStateModel} from '../model';

export function loadingEntities<T extends EntitiesStateModel<any> = EntitiesStateModel<any>>(
  loading: boolean
): StateOperator<T> {
  // @TODO: Remove "as any"
  return patch<EntitiesStateModel<any>>({
    loading,
  }) as any;
}
